<template>
  <v-dialog :value="$attrs.value" persistent max-width="320">
    <v-card>
      <v-card-title class="text-h5">Discard changes?</v-card-title>

      <v-card-text>
        If you decide to leave this page, all changes will be lost. Are you sure you want to leave?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="exit">Yes</v-btn>
        <v-btn color="primary darken-1" @click="$emit('decline')">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'pibot-confirm-exit-dialog',
  props: {
    to: {
      type: [Object, String],
      default: null
    }
  },
  methods: {
    exit () {
      if (this.to) this.$router.push(this.to)
      else this.$emit('confirm')
    }
  }
}
</script>
